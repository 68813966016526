.App {
  padding: 25px;
  background-color: #ECEFF1;
}

.github-profile {
  margin-top: 15px;
}

.github-profile ul {
  text-align: center;
  padding: 0;
}

.github-profile ul li {
  display: inline-block;
  text-align: center;
  padding: 5px;
}

.github-profile ul li:hover {
  background-color: #e1e1e1;
  border-radius: 3px;
}

.github-profile a {
  text-decoration: none;
  color: black;
}

.github-profile ul li a i {
  display: block;
  font-size: 26px;
}

.word-document {
  margin-top: 15px;
}